<template>
  <div class="contaier" :class="{hideSidebar: isCollapse}">
    <Sidebar class="sidebar-container" :isCollapse="isCollapse">
      <!-- <div
        class="sidebar-togglebtn"
        :class="{collapse: isCollapse}"
        @click="toggleSideBar"
      >
      <el-icon><Back /></el-icon>
    </div> -->
    </Sidebar>

    <div class="main-container" id="mainContainer">
      <Header></Header>

      <!-- 内容区 -->
      <AppMain></AppMain>
    </div>
  </div>

</template>


<script>
import { ref } from "vue";
import Header from "./components/Header.vue";
import Sidebar from "./components/Sidebar/Index.vue";
import AppMain from "./components/AppMain.vue";

export default {
  components: {
    Header,
    Sidebar,
    AppMain,
  }, 
  setup(props) {
    const isNavbar = props.isNavbar!= undefined || true;
    const isCollapse = ref(false);
    
    const toggleSideBar = ()=>{
      isCollapse.value = !isCollapse.value
    }
    return { isCollapse, toggleSideBar, isNavbar };
  },

};
</script>