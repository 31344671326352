import axios from "axios";
import { ElMessageBox } from "element-plus";
import showMsg from "@/utils/message";
import store from "@/store/index";

const instance = axios.create({
  // 在请求地址前面加上baseURL
  baseURL: process.env.VUE_APP_BASE_URL,
  // 设置请求超时时间
  timeout: 6000,
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["examinee-token"] = store.getters.token;
    }
    config.headers["debug"] = process.env.VUE_APP_DEBUG;
    return config;
  },
  (error) => {
    // 请求错误的统一处理
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if(!res.code) {
      return res;
    }
    if (res.code !== 200) {
      if (res.code === 10001 || res.code === 10002) {
        // 重新登录
        ElMessageBox.alert(res.msg, {
            confirmButtonText: "重新登录",
            type: "warning",
            "show-close": false,
          })
          .then(() => {
            store.dispatch("user/resetToken").then(() => {
              location.reload();
            });
          });
      }
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    const { response } = error;
    if(response.status == 408) {
      showMsg.error("请求超时，请刷新后重试",{duration: 5 * 1000,});
    }else {
      showMsg.error(error,{duration: 5 * 1000,});
    }
  }
);

export default instance;