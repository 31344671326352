import request from "@/utils/request";

// 获取地址
export function getRegionList(params) {
    return request({
      url: "/getRegionList",
      method: "GET",
      params
    });
}


/**
 * 导入列表
 * @param {*} type 类型 1考生导入 2试题导入
 * @returns
 */
 export function getBatchList(params) {
  return request({
    url: "/getBatchList",
    method: "GET",
    params,
  });
}

/**
 * 下载错误文件
 * @param {*} batch_id
 * @returns
 */
 export function downloadErrorBatchList(params) {
  return request({
    url: "/downloadErrorBatchList",
    method: "GET",
    params,
    header: {
      headers: {'Content-Type': 'application/x-download'},
    },
    responseType: "blob", 
  });
}

// 获取机构列表
export function getAgencyList() {
  return request({
    url: "/getAgencyList",
    method: "get",
  });
}



/**
 * 获取通知列表
 * @param {*} 
 * @returns
 */
 export function getNoticeList(params) {
  return request({
    url: "/getNoticeList",
    method: "get",
    params
  });
}

/**
 * 获取通知
 * @param {*} id 
 * @returns
 */
export function getNotice(params) {
  return request({
    url: "/getNotice",
    method: "get",
    params
  });
}

