import { getRegionList } from "@/api/common";

export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
  }
  
  export function timeFormat(timestamp) {
    if (!timestamp) {
      return timestamp;
    }
  
    var value = parseInt(timestamp) * 1000;
    var time = new Date(value);
    var year = time.getFullYear();
    var month = time.getMonth() + 1;
    var date = time.getDate();
    var hour = time.getHours();
    var minute = time.getMinutes();
    var second = time.getSeconds();
    month = month < 10 ? "0" + month : month;
    date = date < 10 ? "0" + date : date;
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;
    return (
      year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
    );
  }

export function getRegion(pid) {
    getRegionList(pid).then((res)=>{
        return res.data
    })
}


export function topicTypeText(id) {
    switch (id) {
        case 1:
          return {
            title: "单选题",
            type: "primary",
          };
        case 2:
          return {
            title: "多选题",
            type: "success",
          };
        case 3:
          return {
            title: "判断题",
            type: "danger",
          };

        case 4:
            return {
                title: "问答题",
                type: "primary",
                };

        case 5:
            return {
                title: "填空题",
                type: "warning",
            };
    
        case 6:
            return {
                title: "案例题",
                type: "success",
              };
        
        default: 
            return "";
      }
}

export function topicTypeList() {
    return [
        {
            id: 1,
            name: "单选题",
        },
        {
            id: 2,
            name: "多选题"
        },
        {
            id: 3,
            name: "判断题"
        },
        {
            id: 4,
            name: "问答题"
        },
        {
            id: 5,
            name: "填空题"
        },
        {
            id: 6,
            name: "案例题"
        },
    ]
}

export function difficultyText(id) {
    switch (id) {
        case 1:
            return {type: "danger", name: "难"};
          
        case 2:
            return {type: "warning", name: "中"};
    
        case 3:
            return {type: "primary", name: "易"};
        
        default: 
            return {};
      } 
}

export function difficultyList() {
    return [
        {
            id: 1,
            name: "难"
        },
        {
            id: 2,
            name: "中"
        },
        {
            id: 3,
            name: "易"
        },
    ]
}

/**
 * 检查是否手机号码
 * @param {*} mobile 
 * @returns {Boolean}
 */
export function isMobile(mobile) {
    var reg = /^1[3-9]\d{9}$/;
    return reg.test(mobile);
}

/**
 * 检查是否邮箱
 * @param {*} email 
 * @returns {Boolean}
 */
export function isEmail(email) {
    var reg = /^[\w\+\-]+(\.[\w\+\-]+)*@[a-z\d\-]+(\.[a-z\d\-]+)*\.([a-z]{2,4})$/i;
    return reg.test(email);
}