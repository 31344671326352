import {createStore} from 'vuex'
import user from "./modules/user";
import permission from "./modules/permission";

const store = createStore({
    modules: {
        user,
        permission,
    },
    getters: {
        token: state => state.user.token,
        roles: state => state.user.roles,
        hasAuth: state => state.permission.hasAuth,
        permissionRoutes: state => state.permission.routes,
    },
})

export default store