import request from "@/utils/request";
import { getToken, setToken, removeToken } from "@/utils/auth";
import Cookies from "js-cookie";

const state = {
  token: getToken(),
  roles: [],
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
};

const actions = {
  // 登录
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      request({
        url: "/login/login",
        method: "post",
        data: userInfo,
      })
        .then((response) => {
          const { data } = response;
          // 存放令牌状态
          commit("SET_TOKEN", data.examinee_token);
          // commit("SET_ROLES", roles);
          // Cookies.set(roles, roles);
          setToken(data.examinee_token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 获取账号角色
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      request({
        url: "/user/info",
        method: "post",
        data: { token: state.token },
      })
        .then((response) => {
          const { data } = response;
          commit("SET_ROLES", data.roles);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 退出登录
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      removeToken();
      resolve();
      // request({
      //   url: "/user/logout",
      //   method: "post",
      //   data: "",
      // })
      //   .then(() => {
      //     // 清除令牌状态
      //     commit("SET_TOKEN", "");
      //     resolve();
      //   })
      //   .catch((error) => {
      //     reject(error);
      //   });
    });
  },
  // user login
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      removeToken();
      Cookies.remove("roles");
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
