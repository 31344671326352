<template>
  <el-header class="header" id="headtop">
    <div class="headtop">
      <div class="txt">
        <el-icon color="#f5890a" size="20"><WarningFilled /></el-icon>
        为了便于您的阅读，建议使用浏览器：Microsoft Edge、360极速模式、Google 10. Firefox
        3.2以上版本访问系统。如果您的屏幕分辨率较小，请使用快捷键Ctrl+/-进行屏幕缩放。
      </div>
      <div class="system">
        <router-link to="/profile" class="link" alt="profile"
          ><el-icon><UserFilled /></el-icon
        ></router-link>
        <div @click="logout" class="link logout" alt="logout">
          <el-icon><SwitchButton /></el-icon>
        </div>
      </div>
    </div>
  </el-header>
</template>

<script>

export default {
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  }
};
</script>

<style lang="less" scoped>

.el-header {
  padding: 0;
}

.header {
  position: fixed;
  top: 0;
  left: @leftWidth;
  background-color: #fff;
  display: flex;
  height: @headtopHeight;
  width: calc(100% - @leftWidth);
  z-index: @headZindex;
  .headtop {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;
    flex-grow: 1;
    .txt {
      color: #999999;
      font-size: 14px;
    }
  }
  .system {
    flex-shrink: 0;
    .link {
      display: inline-block;
      margin-left: 5px;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: #334a7d;
      text-align: center;
      line-height: 34px;
      color: #fff;
      cursor: pointer;
      .transition;
      &:hover {
        background-color: @color_deep;
      }
      &.logout {
        background-color: @color_yellow;
        &:hover {
          opacity: 0.8;
      }
      }
    }
  }
  .el-icon {
    vertical-align: middle;
  }
}
</style>
