<template>
  <el-scrollbar class="scrollbar">
      <div class="sidebar-con" >
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="大营家logo" />
        </div>
        
        <el-menu
          :default-active="activeMenu"
          :collapse="isCollapse"
          :unique-opened="true"
        >
          <sidebar-item
            v-for="route in permissionRoutes"
            :key="route.path"
            :item="route"
            :base-path="route.path"
          ></sidebar-item>
        </el-menu>
      <slot />
    </div>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem.vue";

export default {
  props: ["isCollapse"],
  components: { SidebarItem },
  computed: {
    ...mapGetters(["permissionRoutes"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
};
</script>

<style lang="less" scoped></style>
