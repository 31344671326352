<template>
  <router-view></router-view>
</template>


<script>

export default {
  name: 'App',
  components: {
  },
  mounted() {
    document.title = '考务系统-学生管理';
  },
  setup(){
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      }
    }
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    }
  }
}
</script>
