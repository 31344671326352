import { asyncRoutes, constantRoutes } from "@/router";
import router from "@/router";

/**
 * 过滤路由
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

/**
 * 过滤路由
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  const res = [];
  routes.forEach((route) => {
    if (hasPermission(roles, route)) {
      if (route.children) {
        route.children = filterAsyncRoutes(route.children, roles);
      }
      res.push(route);
    }
  });
  return res;
}

const state = {
  routes: [],
  hasAuth: false,
};
const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = constantRoutes.concat(routes);
  },
  HAS_AUTH: (state, val) => {
    state.hasAuth = val
  }
};
const actions = {
  generateRoutes({ dispatch, commit }, roles = "admin") {
    return new Promise((resolve) => {
      let accessedRoutes;
      if (roles.includes("admin")) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      }
      commit("SET_ROUTES", accessedRoutes);
      commit("HAS_AUTH", true);
      dispatch("addRoutes", accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  addRoutes(context, accessRoutes) {
    accessRoutes.forEach((route) => {
      router.addRoute(route);
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
