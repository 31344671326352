import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import showMsg from "@/utils/message";

import Layout from "@/views/Layout/Index.vue";

const Login = () => import("@/views/Login.vue");

export const constantRoutes = [
  { path: "/login", name: "login", component: Login, hidden: true },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/Index/Index.vue"),
        name: "Home",
        meta: { title: "首页", icon: "el-icon-home",noNav:true },
      },
    ],
  },
];

// 权限路由
export const asyncRoutes = [
  {
    path: "/profile",
    component: Layout,
    meta: {
      title: "个人中心",
      icon: "el-icon-user-fill",
    },
    children: [
      {
        path: "",
        component: () => import("@/views/Profile/Index.vue"),
        name: "Profile",
        meta: { title: "个人中心", icon: "el-icon-user-fill",},
      },
    ],
  },
  {
    path: "/exam_manage",
    component: Layout,
    meta: {
      title: "考试管理",
      icon: "el-icon-kaoshiguanli",
    },
    children: [
      {
        path: "apply",
        component: () => import("@/views/examManage/apply.vue"),
        name: "apply",
        meta: { title: "报名考试", },
      },
      {
        path: "mock",
        component: () => import("@/views/examManage/indexMock.vue"),
        name: "Mock",
        meta: { title: "模拟测验", },
      },
      {
        path: "formal",
        component: () => import("@/views/examManage/indexFormal.vue"),
        name: "Formal",
        meta: { title: "正式考试", },
      },
      {
        path: "exam/:is_formal(\\d+)?/:id(\\d+)/:is_face_check?",
        component: () => import("@/views/examManage/Exam.vue"),
        hidden: true,
        meta: { title: "进入考试", },
      },
      {
        path: "info/:exam_paper_id(\\d+)",
        component: () => import("@/views/examManage/Info.vue"),
        hidden: true,
        meta: { title: "考试详情", },
      },
    ],
  },
  {
    path: "/notice",
    component: Layout,
    meta: {
      title: "通知管理",
      icon: "el-icon-bumenwenjian",
    },
    children: [
      {
        path: "",
        component: () => import("@/views/notice/index.vue"),
        meta: {
          title: "通知管理",
          icon: "el-icon-bumenwenjian",
        },
      },
    ]
  },
  {
    path: "/changepwd",
    component: Layout,
    meta: {
      title: "修改密码",
      icon: "el-icon-mima",
    },
    children: [
      {
        path: "",
        component: () => import("@/views/password/index.vue"),
        meta: {
          title: "修改密码",
          icon: "el-icon-mima",
        },
      },
    ]
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  base: '/'
});

// 白名单
const whiteList = ["/login"];

// 添加权限路由
router.beforeEach(async (to, from, next) => {
  // 用户是否登录
  const debug = process.env.VUE_APP_DEBUG;
  const hasToken = store.getters.token;
  if (hasToken || debug == 1) {
    //已登录
    if (to.path === "/login") {
      // 重定向至首页
      next({ path: "/" });
    } else {
      const hasAuth = store.getters.hasAuth;
      if(hasAuth){
        next();
      }else {
        try {
          store.dispatch("permission/generateRoutes");
          next({ ...to, replace: true });
        } catch (error) {
          // 重新登录
          await store.dispatch("user/resetToken");
          showMsg.error(error || "请重新登录");
          next(`/login?redirect=${to.path}`);
        }
      }
    }
  } else {
    // 未登录
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next({ path: "/login", query: { redirect: to.path } });
    }
  }
});

export default router;
